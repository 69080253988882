<template>
  <div class="container">
    <div class="auth_login_form">
      <div class="va-card mb-4">
        <div class="dash_card_body ">

          <form @submit.prevent="login">
            <div v-if="regError" >
              <div class="alert alert-danger">
                <ul>
                  <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <label>Name:</label>
              <div>
                <input type="text" v-model="name" @blur="v$.name.$touch" class="form-control" />
              </div>
              <div class="input_error" v-if="v$.name.$error">
                <span v-if="v$.name.required.$invalid">Name is required</span>
              </div>

            </div>
            <div class="form-group">
              <label>Email:</label>
              <div>
                <input type="email" v-model="email" @blur="v$.email.$touch" class="form-control" />
              </div>
              <div class="input_error" v-if="v$.email.$error">
                <span v-if="v$.email.required.$invalid">Email is required</span>
                <span v-if="v$.email.email.$invalid">Email is invalid</span>

              </div>

            </div>

            <div class="form-group">
              <label>Phone:</label>
              <div>
                <input type="text" v-model="phone" @blur="v$.phone.$touch" class="form-control" />
              </div>
              <div class="input_error" v-if="v$.phone.$error">
                <span v-if="v$.phone.required.$invalid">Phone is required</span>
              </div>

            </div>

            <div class="form-group">
              <label>Password:</label>
              <div>
                <input type="password" v-model="password" @blur="v$.password.$touch" class="form-control" />
                <div class="input_error" v-if="v$.password.$error">
                  <span v-if="v$.password.required.$invalid">Password is required</span>
                  <span v-if="v$.password.minLength.$invalid">Password must be at least 6 characters</span>
                                
                </div>
              </div>
            </div>


            <div class="form-group">
              <label>Role:</label>
              <div>
                <select class="form-control" v-model="role">
                  <option value="">-Select Role-</option>
                  <option v-for="rol in roles" :key="rol.id" :value="rol.id">{{rol.name}}</option>
                </select>
              </div>
              <div class="input_error" v-if="v$.role.$error">
                <span v-if="v$.role.required.$invalid">Role is required</span>
              </div>

            </div>

            <div class="form-group">
              <label>Merchant:</label>
              <div>
                <select class="form-control" v-model="merchant">
                  <option value="0">-Select Merchant-</option>
                  <option v-for="merchnt in merchants" :key="merchnt.id" :value="merchnt.id">{{merchnt.name}}</option>
                </select>
              </div>
              <div class="input_error" v-if="v$.merchant.$error">
                <span v-if="v$.merchant.required.$invalid">Merchant is required</span>
              </div>

            </div>


            <div class="d-flex justify--center mt-3">
              <va-button @click="register()" class="my-0"><span v-if="loading" v-html="spinner"></span> <span v-else> Sign up </span></va-button>
            </div>
          </form>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            password: '',
            role: '',
            roles:[],
            merchant: 0,
            merchants: [],
            keepLoggedIn: false,
            regError: false,
            errorMsg: '',
            loading: false,
            spinner: "<i class='fa fa-spinner fa-spin '></i> signing up...",
        }
    },
    computed: {

    },
    mounted(){
      this.getRoles();
      this.getMechants();
    },
    validations() {
        return {
            email: { required, email },
            name: { required },
            phone: { required },
            role: { required },
            merchant: { required },
            password: { required, minLength: minLength(6) },
        }
    },
    methods: {
        async getRoles(){
          // 
          const url = this.dashBaseUrl + "/roles";
          this.axios.get(url).then((response) => {
            if(response.status === 200){
              this.roles = response.data.data;
            }
          })
        },
        async getMechants(){
          // 
          const url = this.baseUrl +"/merchant";
          this.axios.get(url).then((response) => {
            if(response.status === 200){
              this.merchants = response.data;
            }
          })
        },
        async register() {
            this.loading = true;
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                this.loading = false;
                return;
            } else {
                const payload = {
                    'name': this.name,
                    'email': this.email,
                    'phone': this.phone,
                    'password': this.password,
                    'organisation_id': this.merchant,
                    'role': this.role
                }
                const url = this.dashBaseUrl + "/auth/register";
                this.axios.post(url, payload, ).then((response) => {
                    if (response.status == 200) {
                      this.loading = false;
                      this.$router.push({
                          name: 'login'
                      });
                    }
                    this.regError = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.regError = true;
                    if(error.response.status === 400){
                      this.errorMsg = error.response.data.errors;
                    }
                    
                });
            }
        }
    },
    mixins: [ReusableDataMixins]
}
</script>
